import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import dateFormat, { masks } from "dateformat";


const BlogSquare = ({ cover, coverAlt, title, published, snippet, slug }) => {

    const coverImageRender = getImage(cover)

    return (
        <div className='w-full flex flex-col'>
            <a className='hover:drop-shadow-2xl' href={'/news/' + slug}>
                <GatsbyImage className='w-full h-auto drop-shadow-lg rounded-md' image={coverImageRender} alt={coverAlt}/>
                <h2 className='mt-5 ws:mt-10 text-3xl ws:text-5xl'>{title}</h2>
                <h3 className='ws:my-3 font-light ws:text-3xl'>{dateFormat(published, "mmm d, yyyy")}</h3>
                <p className='mt-1 ws:text-2xl'>{snippet}</p>
            </a>
        </div>
    )
}

export default BlogSquare